import React, { useState, FormEvent } from "react";
import { FaCertificate, FaEye, FaEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import Input from "../../components/Input";
import { useAppContext } from "../../contexts/app";
import { IdTipoUsuario } from "../../entities/TipoUsuario";
import version from "../../data/version";
import ENV from "../../ENV";

const Login = () => {
  /*const [usertipo, setUsertipo] = useState<number>(IdTipoUsuario.CLIENTE);
  const { cod, senha } = useState<UserAuthProps>(
    ENV.AUTH ? userIsActive[usertipo] : userIsActive[0]
  )[0];*/

  const [usertipo, setUsertipo] = useState<number>(ENV.USER!.tipo);

  const [username, setUsername] = useState(ENV.USER!.cod);
  const [password, setPassword] = useState(ENV.USER!.senha);

  const [showPassword, setShowPassword] = useState(false);
  const { showLoader, hideLoader, login } = useAppContext();

  /*useEffect(() => {
    if (!ENV.AUTH) {
      setUsername("");
      setPassword("");
    }
  }, [usertipo]);*/

  async function handleFormSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      const loader = showLoader("Realizando autenticação do usuário.");

      await login({
        username,
        password,
        usertipo: usertipo, //JL-HF_2021-06-29
      });

      hideLoader(loader);
    } catch (error) {
      let errorMessage =
        "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.";

      /*if (error?.message) {
        errorMessage = error?.response?.data?.message;
      }*/

      Swal.fire({
        icon: "warning",
        title: "Erro",
        text: errorMessage,
        allowEscapeKey: false,
        allowOutsideClick: false,
        confirmButtonText: "OK",
      });
    }
  }

  return (
    <div className="d-flex flex-fill align-items-center">
      <div className="col-sm-8 offset-sm-2 col-xl-4 offset-xl-4 border border-blue p-4">
        <form onSubmit={handleFormSubmit}>
          <h2 className="text-center text-primary">Entrar</h2>
          <p className="text-center">
            Entre com seu e-mail e senha para continuar.
          </p>

          <div className="form-group text-left flex-grow-1">
            <label htmlFor="tipoLogin">Acessar como</label>
            <select
              className="form-control form-control-lg flex-grow-1"
              id="tipoLogin"
              value={usertipo}
              onChange={(e) => setUsertipo(e.target.value as any)}
            >
              {/*JL-HF_2021-06-24*/}
              <option value={IdTipoUsuario.CLIENTE}>Cliente</option>
              <option value={IdTipoUsuario.AGENTE}>Agente</option>
              <option value={IdTipoUsuario.SUPERVISOR}>Gestor</option>
              <option value={IdTipoUsuario.REPRESENTANTE}>Representante</option>
            </select>
          </div>

          <Input
            label={
              Number(usertipo) === IdTipoUsuario.CLIENTE
                ? "CNPJ"
                : "Código Ferrari"
            }
            name="username"
            value={username}
            mask={
              Number(usertipo) === IdTipoUsuario.CLIENTE
                ? "99.999.999/9999-99"
                : "999999"
            }
            className="form-control-lg"
            onChange={(e) => setUsername(e.target.value)}
          />

          <Input
            type={showPassword ? "text" : "password"}
            label="Senha"
            name="password"
            value={password}
            className="form-control-lg"
            onChange={(e) => setPassword(e.target.value)}
            append={
              <span className="position-absolute" style={{ right: "16px" }}>
                {showPassword ? (
                  <FaEyeSlash onClick={() => setShowPassword(false)} />
                ) : (
                  <FaEye onClick={() => setShowPassword(true)} />
                )}
              </span>
            }
          />

          <button type="submit" className="btn btn-block btn-primary btn-lg">
            ENTRAR
          </button>

          <div className="mt-4">
            <p className="text-center">
              Esqueceu sua senha?{" "}
              <Link
                to="/recuperarSenha"
                className="btn btn-link m-0 p-0 align-baseline"
              >
                Clique aqui
              </Link>{" "}
              para recebê-la por e-mail.
            </p>
          </div>
          {/* JL_2022-06 -> versionamento da aplicação*/}
          <div className="text-right text-version">
            <FaCertificate color="#204d74" />{" "}
            <span title="Versão atual da aplicação">{version}</span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
