import { IdTipoUsuario } from "./entities/TipoUsuario";

export interface UserAuthProps {
  cod: string;
  senha: string;
  tipo: number;
}

export interface UserAuthTypeProps {
  default: UserAuthProps;
  cliente: UserAuthProps;
  representante: UserAuthProps;
  gestor: UserAuthProps;
  agente: UserAuthProps;
}

export const userIsActive: UserAuthTypeProps = {
  default: {
    cod: ``,
    senha: ``,
    tipo: IdTipoUsuario.CLIENTE,
  },
  cliente: {
    cod: `25.008.157/0001-94`,
    senha: `26032016Br.`,
    tipo: IdTipoUsuario.CLIENTE,
  },
  representante: {
    cod: `000447`,
    senha: `1234`,
    tipo: IdTipoUsuario.REPRESENTANTE,
  },
  agente: {
    cod: "000378", //"000444", //"000439", //"000438", //`000439`,
    senha: "1234", //"000002", //"000003", //"000004", //`000003`,
    tipo: IdTipoUsuario.AGENTE,
  },
  gestor: {
    cod: `001029`,
    senha: `401862`,
    tipo: IdTipoUsuario.SUPERVISOR,
  },
} as UserAuthTypeProps;

export interface envURLProps {
  AUTH: boolean; //Realiza login automático *USAR SOMENTE NO AMBIENTE DESENVOLVIMENTO*
  USER: UserAuthProps;
  API_URL: string;
  ASSETS_URL: string;
}

export interface envURLTypeProps {
  production: envURLProps;
  development: envURLProps;
  homologacao: envURLProps;
}

const envURL: envURLTypeProps = {
  /*AMBIENTE: DEV-LOCAL WEB*/
  development: {
    AUTH: true, //Realiza login automático *USAR SOMENTE NO AMBIENTE DESENVOLVIMENTO*
    USER: userIsActive.representante,
    API_URL: `http://localhost:3333`,
    ASSETS_URL: `https://b2b.ferrarinet.com.br/assets`, //https://teste-b2b.rexon.com.br/assets
  },
  /*AMBIENTE: DEV-HOMOLOG WEB*/
  homologacao: {
    AUTH: false,
    USER: userIsActive.default,
    API_URL: `https://teste-b2b.rexon.com.br/api`,
    ASSETS_URL: `https://teste-b2b.rexon.com.br/assets`,
  },
  /*AMBIENTE: PRODUÇÃO WEB*/
  production: {
    AUTH: false,
    USER: userIsActive.default,
    API_URL: `https://b2b.rexon.com.br/api`,
    ASSETS_URL: `https://b2b.ferrarinet.com.br/assets`, //`https://b2b.rexon.com.br/assets`,
  },
} as envURLTypeProps;

//nvm use 10.15.1

const ENV = envURL.production; //"development" | "production" | "homologacao"

export default ENV;
