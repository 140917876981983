const version = "v1.0.3";

/* Desenvolvimento, melhorias, correções e ajustes */

/* Versão: v1.0.3
 * Data: 2024-06-25
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas
 * Projeto: Ajustes de funcionalidades
 * - Pedido:
 *    - Adicionar produtos enviar somente os que tiver itens selecionado
 *     - Adicionar checkbox para informa que os itens precisa ter mesmo lote de serie
 *         -Exibir somente para os produtos = 005, 027
 *    - Enviar na Rota HFDA15 (Rota de confirmação do pedido)
 *      - _cUConf		:=	Alltrim(oXml:uconf) -> string 1 carácter S ou N
 *      - _cMenNo	:=	Alltrim(oXml:menniota) -> (Label: Observação de frete) somente quando for frete FOB -> mensagem da nota, string 50 caracteres
 * /

/* Versão: v1.0.2
 * Data: 2024-06-25
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas
 * Projeto: Ajustes de funcionalidades
 * - Pedido:
 *                  - URL das imagens produtos
 *                  - Ajustes no backend
 * /

/* Versão: v1.0.1
 * Data: 2024-05-21
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas
 * Projeto: Ajustes de funcionalidades
 * - Pedido:
 *                  - Criando nova tela pedido para não realizar calculo buscando do Protheus
 *                  - Calculo de valor, entrega e impostos buscando do Protheus só deve ser realizado ao clicar no botão "Calcular"
 * - Tratativa Error:
 *                  - Realizar tratativa do retorno error quando carrega planilha excel
 * /

/* Versão: v1.0.0
 * Data: 2024-03-15
 * Developer: Josuel Lopes - HF
 * Solicitante: Douglas Chagas
 * Projeto: Replica do ambiente completo do B2B para REXON
 * - Backend:
 *                  - Ajustes nas APIs de acesso ao Protheus REXON
 *                  - Ajustar das funcionalidades para dados retornado da APIs
 *
 * SINCRONIZA ASSISTÊNCIA TÉCNICA: FALHA PARSE NA SINCRONIZAÇÃO
 *
 * - Frontend:
 *                  - Ajuste das funcionalidades para dados retornados da APIs
 */

export default version;
