import axios, { AxiosResponse } from "axios";

import ENV from "../ENV";
import {
  Transportadora,
  RelatorioEmailPedidosFaturamento,
  ProdutosAtualizarMpp,
  ProspectProps,
} from "../typings";

const api = axios.create({ baseURL: ENV.API_URL, withCredentials: true });

api.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (config) => config,
  (error) => Promise.reject(error)
);

/*JL-HF_2023-10*/
const apiFiles = axios.create({ baseURL: `http://localhost:3337` }); //{ baseURL: ENV.API_URL, withCredentials: true });
apiFiles.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

apiFiles.interceptors.response.use(
  (config) => config,
  (error) => Promise.reject(error)
);

/*-----------------------------------------------*/

async function login({
  username,
  password,
  usertipo,
}: {
  username: string;
  password: string;
  usertipo: number;
}) {
  const response = await api.post("/auth/login", {
    username,
    password,
    usertipo,
  });

  return response;
}

function logout() {
  return api.post("/auth/logout");
}

function askPasswordChange({ cnpj, email }: { cnpj: string; email: string }) {
  return api.post("/usuario/solicitarRecuperacaoSenha", { cnpj, email });
}

function confirmPasswordChange({
  id,
  codigo,
  novaSenha,
}: {
  id: string;
  codigo: string;
  novaSenha: string;
}) {
  return api.post("/usuario/recuperarSenha", { id, codigo, novaSenha });
}

function changePassword({
  senha,
  novaSenha,
}: {
  senha: string;
  novaSenha: string;
}) {
  return api.post("/usuario/alterarSenha", { senha, novaSenha });
}

function fetchLoggedUser() {
  return api.get("/auth");
}

function fetchClientesRepresentante(sortParam: string | null = null) {
  return api.get("/personificacao", {
    params: { sort_by: sortParam, show_all_items: true },
  });
}

async function updateClienteAtivo({
  codigo,
  loja,
}: {
  codigo: string;
  loja: string;
}) {
  const response = await api.post("/personificacao", { codigo, loja });

  return response;
}

async function fetchProdutos({
  searchText,
  grupo,
  all,
}: {
  searchText: string;
  grupo: string;
  all?: boolean;
}) {
  //Add filter por codigo produto - JL-HF_2022-11 (Solicitado por Douglas Chagas)
  if (grupo !== undefined && grupo?.length > 0 && all !== false) {
    searchText = "*";
  }

  const filter = `filter_by=codigo*${searchText}&filter_by=titulo*${searchText}`; //&filter_by=codigogrupo*${searchText}

  return api.get(`/produto?${filter}`, { params: { show_all_items: true } });
}

// -----------------------------------------------------------------------------
// Rotas Privadas - Pesquisa lista de produtos - JL-HF_2023-10
async function fetchProdutosPesquisa({
  searchText,
  grupo,
  all = true,
}: {
  searchText: string;
  grupo: string;
  all?: boolean;
}) {
  if (grupo !== undefined && grupo?.length > 0 && all !== false) {
    searchText = "*";
  }

  const filter = `filter_by=codigo*${searchText}&filter_by=titulo*${searchText}`; //&filter_by=codigogrupo*${searchText}

  return api.get(`/produto/pesquisa/?${filter}`, {
    params: { show_all_items: true },
  });
}

function fetchCondicoesPagamento() {
  return api.get("/produto/condicoes");
}

function fetchMPP({
  condicaoPagamento,
  calcularImpostos = "false",
  calcularEntregas = "false",
}: {
  condicaoPagamento?: string;
  calcularImpostos: string;
  calcularEntregas: string;
}) {
  return api.get("/produto/sugeridos", {
    params: { condicaoPagamento, calcularImpostos, calcularEntregas },
  });
}

function fetchPromocoes({
  condicaoPagamento,
  calcularImpostos = "false",
  calcularEntregas = "false",
  calcularQuantidadeMes = "false",
}: {
  condicaoPagamento?: string;
  calcularImpostos: string;
  calcularEntregas: string;
  calcularQuantidadeMes: string;
}) {
  return api.get("/produto/promocoes", {
    params: {
      condicaoPagamento,
      calcularImpostos,
      calcularEntregas,
      calcularQuantidadeMes,
    },
  });
}

function updateItems({
  condicaoPagamento,
  itens,
  calcularImpostos = "false",
  calcularEntregas = "false",
  calcularQuantidadeMes = "false",
}: {
  condicaoPagamento: string;
  itens: { codigo: string; quantidade: number; percentualDesconto: number }[];
  calcularImpostos: string;
  calcularEntregas: string;
  calcularQuantidadeMes: string;
}) {
  return api.post("/produto/produtoPedido", {
    condicaoPagamento,
    itens,
    calcularImpostos,
    calcularEntregas,
    calcularQuantidadeMes,
  });
}

function uploadPlanilha({
  planilha,
  calcularImpostos = "false",
  calcularEntregas = "false",
  calcularQuantidadeMes = "false",
}: {
  planilha: File;
  calcularImpostos: string;
  calcularEntregas: string;
  calcularQuantidadeMes: string;
}) {
  const formData = new FormData();

  if (planilha) {
    formData.append("upload", planilha);
    formData.append("calcularImpostos", calcularImpostos);
    formData.append("calcularEntregas", calcularEntregas);
    formData.append("calcularQuantidadeMes", calcularQuantidadeMes);
  }

  return api.post("/produto/uploadPlanilha", formData);
}

function fetchHistoricoCompraProduto(codigo: string) {
  return api.get("/cliente/historico", { params: { codigo } });
}

function fetchFaturas() {
  return api.get("/cliente/titulos");
}

function fetchPedidos() {
  return api.get("/cliente/pedidos");
}

function fetchTransportadoras() {
  return api.get("/transportadora", {
    params: { show_all_items: true, sort_by: "nome:asc" },
  });
}

function createMotivoSaida({ motivo }: { motivo: string }) {
  return api.post("/motivoSaida", { motivo });
}

// -----------------------------------------------------------------------------
// Rotas Privadas - Campanha Compre Ganhe - JL_2021-04

function fetchSaldoMetaCliente(cnpj: string, codigo: string) {
  const data = api.get("/cliente/saldoMeta", { params: { cnpj, codigo } });
  return data;
}

function fetchProdutosCampanha({ cnpj }: { cnpj: string }) {
  return api.get("/produto/campanha", { params: { cnpj } });
}

function fetchProdutosParcialCampanha({ cnpj }: { cnpj: string }) {
  return api.get("/produto/campanha/parcial", { params: { cnpj } });
}

// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
//Grupo de produtos por CNPJ - JL_2022-11
async function fetchGruposProdutosPorCNPJ({ cnpj }: { cnpj: string }) {
  const grupoProdutos: any = await api.get("/produto/grupo", {
    params: { cnpj },
  });

  if (grupoProdutos?.data) {
    return grupoProdutos.data;
  }

  return [];
}

// -----------------------------------------------------------------------------
//Lista para atualização dos produtos MPP por codigo cliente - JL_2023-09

async function updateProdutoSugeridosAtualizaMPPCodigoCliente(
  data: ProdutosAtualizarMpp
) {
  const response: any = await api.post(
    "/produto/sugeridos/atualizar/mpp",
    data
  );

  return response;
}
// -----------------------------------------------------------------------------

function fetchProdutosAll() {
  return api.get("/produto");
}

// Produto - Consulta disponibilidade do produto
async function fetchDisponibilidadeProduto({
  codigo,
  quantidade,
}: {
  codigo: string;
  quantidade: string;
}) {
  const data = {
    codigo,
    quantidade,
  };

  const response: any = await api.get("/produto/consulta/disponibilidade", {
    params: data,
  });

  if (response?.data) {
    return response.data;
  }

  return null;
}

function createPedido({
  condicaoPagamento,
  tipoFrete,
  tipoPedido, //JL-HF_2021-04
  valorFrete,
  transportadora,
  pedidoCliente,
  pedidoRepresentante,
  aceitaParcial,
  dataProg,
  justificativaDesconto,
  observacoesPedidoFOB, //JL-HF_2024-08
  itens,
}: {
  condicaoPagamento: string;
  tipoFrete: string;
  tipoPedido: string; //JL-HF_2021-04
  valorFrete: number;
  transportadora: Transportadora | null;
  pedidoCliente: string;
  pedidoRepresentante: string;
  aceitaParcial: boolean;
  dataProg: string;
  justificativaDesconto: string;
  observacoesPedidoFOB: string; //JL-HF_2024-08
  itens: {
    codigo: string;
    quantidade: number;
    percentualDesconto: number;
    entrega2Data: string;
  }[];
}) {
  console.log(">>FOB API");
  console.log(observacoesPedidoFOB);
  return api.post("/pedido", {
    condicaoPagamento,
    tipoFrete,
    tipoPedido, //JL-HF_2021-04
    valorFrete,
    transportadora,
    pedidoCliente,
    pedidoRepresentante,
    aceitaParcial,
    dataProg,
    justificativaDesconto,
    observacoesPedidoFOB, //JL-HF_2024-08
    itens,
  });
}

//------------------------------------------------------------------------------
//JL-HF_2022-06 - ORÇAMENTO
function createOrcamento({
  condicaoPagamento,
  pedidoCliente,
  pedidoRepresentante,
  justificativaDesconto,
  valorTotalSemImpostos,
  valorTotalComImpostos,
  valorTotalDescontos,
  ipiTotal,
  stTotal,
  quantidadeItens,
  enviaAprovacao,
  tipoFrete,
  itens,
}: {
  condicaoPagamento: string;
  pedidoCliente: string;
  pedidoRepresentante: string;
  justificativaDesconto: string;
  valorTotalSemImpostos: number;
  valorTotalComImpostos: number;
  valorTotalDescontos: number;
  ipiTotal: number;
  stTotal: number;
  quantidadeItens: number;
  enviaAprovacao: boolean;
  tipoFrete: string;
  itens: {
    codigo: string;
    quantidade: number;
    percentualDesconto: number;
    entrega2Data: string;
  }[];
}) {
  return api.post("/orcamento", {
    condicaoPagamento,
    pedidoCliente,
    justificativaDesconto,
    valorTotalSemImpostos,
    valorTotalComImpostos,
    valorTotalDescontos,
    ipiTotal,
    stTotal,
    quantidadeItens,
    enviaAprovacao,
    tipoFrete,
    itens,
  });
}

function fetchClienteOrcamento() {
  return api.get("/orcamento/cliente");
}

function fetchInteracoesOrcamento({ id }: { id: number }) {
  return api.get("/orcamento/interacao", {
    params: { id },
  });
}

function gravarOrcamentoInteracao({
  id,
  status,
  justificativa,
}: {
  id: string;
  status: number;
  justificativa: string;
}) {
  return api.post("/orcamento/interacao", {
    id,
    status,
    justificativa,
  });
}

function solicitaAprovacaoOrcamento({
  id,
  status,
  justificativa,
}: {
  id: number;
  status: number;
  justificativa: string;
}) {
  return api.post("/orcamento/cliente/analise", {
    id,
    status,
    justificativa,
  });
}

function fetchSupervisorOrcamentos() {
  return api.get("/orcamento/supervisor");
}

function supervisorOrcamentosCarregarProdutos({
  revendedorCnpj,
  condicaoPagamento,
  itens,
  calcularImpostos = "false",
  calcularEntregas = "false",
  calcularQuantidadeMes = "false",
}: {
  revendedorCnpj: string;
  condicaoPagamento: string;
  itens: { codigo: string; quantidade: number; percentualDesconto: number }[];
  calcularImpostos: string;
  calcularEntregas: string;
  calcularQuantidadeMes: string;
}) {
  return api.post("/orcamento/supervisor/produtos", {
    revendedorCnpj,
    condicaoPagamento,
    itens,
    calcularImpostos,
    calcularEntregas,
    calcularQuantidadeMes,
  });
}

function supervisorAprovarOrcamento({
  id,
  status,
  justificativa,
}: {
  id: string;
  status: number;
  justificativa: string;
}) {
  return api.post("/orcamento/supervisor/aprovar", {
    id,
    status,
    justificativa,
  });
}

function supervisorRejeitarOrcamento({
  id,
  status,
  justificativa,
}: {
  id: string;
  status: number;
  justificativa: string;
}) {
  return api.post("/orcamento/supervisor/rejeitar", {
    id,
    status,
    justificativa,
  });
}

function solicitaOrcamentoPdf({
  email,
  orcamento,
}: {
  email: string;
  orcamento: string;
}) {
  return api.post("/orcamento/cliente/pdf", {
    email,
    orcamento,
  });
}

function downloadPDFOrcamento({ orcamentoId }: { orcamentoId: string }) {
  return api.post(`/orcamento/pdf/${orcamentoId}`);
}

function solicitaPedidoPdf({
  email,
  pedido,
}: {
  email: string;
  pedido: string;
}) {
  return api.post("/cliente/pedido/pdf", {
    email,
    pedido,
  });
}

function solicitaNotaFiscaPdf({
  email,
  nota,
  serie,
}: {
  email: string;
  nota: string;
  serie: string;
}) {
  return api.post("/cliente/nota/pdf", {
    email,
    nota,
    serie,
  });
}

//------------------------------------------------------------------------------
//JL-HF 2023-06 - START: RELATÓRIOS SOLICITA ENVIO EMAIL
function fetchGrupoVendasAll(sortParam: string | null = null) {
  return api.get("/grupo/vendas", {
    params: { sort_by: sortParam, show_all_items: true },
  });
}

async function solicitaRelatorioPedido(data: RelatorioEmailPedidosFaturamento) {
  return await api.post("/relatorios/email/pedidos", data);
}

async function solicitaRelatorioFaturamento(
  data: RelatorioEmailPedidosFaturamento
) {
  return await api.post("/relatorios/email/faturamentos", data);
}

//------------------------------------------------------------------------------
//PROSPECT - JL-HF_2023-09
async function sendCreateProspect(data: ProspectProps): Promise<AxiosResponse> {
  const response: AxiosResponse = await api.post("/prospects/cadastrar", data);
  return response;
}

async function sendUpdateProspect(data: ProspectProps): Promise<AxiosResponse> {
  const response: AxiosResponse = await api.put("/prospects/atualizar", data);
  return response;
}

async function sendDeleteProspect(data: any) {
  const response: any = await api.put("/prospects/deletar", data);

  return response;
}

async function sendEfetivarProspect(data: any) {
  const response: any = await api.put("/prospects/efetivar", data);

  return response;
}

async function fetchProspect(codigo: any) {
  const response: any = await api.get("/prospects", {
    params: { codigo },
  });

  if (response?.data) {
    return response.data;
  }

  return [];
}

async function fetchProspectCodigo({
  codigo,
  loja,
}: {
  codigo?: string;
  loja?: string;
}) {
  const data = {
    codigo: String(codigo), //: "018274",
    loja: "",
  };
  const response: any = await api.get("/prospect/busca", { params: data });

  if (response?.data) {
    return response.data;
  }

  return [];
}

//------------------------------------------------------------------------------
// AGENDA

async function fetchAgendaCodigoInatividade() {
  const response: any = await api.get("/agenda/contatos/codigosinatividade");

  if (response?.data) {
    return response.data;
  }

  return [];
}

async function fetchAgendaConcorrentes() {
  const response: any = await api.get("/agenda/contatos/concorrentes");

  if (response?.data) {
    return response.data;
  }

  return [];
}

/*async function sendAgendaRegistroDeContatoAnexos({
  files,
  codigo,
  tipo,
}: {
  files: any | undefined;
  codigo: string;
  tipo: string;
}) {
  const qtdPrefixo = "00000000";
  console.log(">>ANEXOS");
  console.log(files);
  //formData.append("upload", files[0]);
  const formData = new FormData();
  for (var f of files) {
    await formData.append(`upload`, f);
    // const response: any = await apiFiles.post("/upload", formData, {
    //   params: {
    //     codigo: `${qtdPrefixo.slice(String(codigo).length)}${codigo}`,
    //     tipo: tipo,
    //   },
    // });

    // return response;
  }

  return await formData;
  /* UPLOAD - EXTERNO
  const response: any = await apiFiles.post("/upload", formData, {
    headers: {
      ...formData,
    },
    params: {
      codigo: `${qtdPrefixo.slice(String(codigo).length)}${codigo}`,
      tipo: tipo,
    },
    return response;
  });*/
/*}*/

async function sendAgendaRegistroContatoProsppect({ data, files }: any) {
  //const { codigo, tipoContato } = data;

  /*if (files.length > 0) {
    await sendAgendaRegistroDeContatoAnexos({
      files,
      codigo,
      tipo: tipoContato,
    });
  }*/

  //return await api.post("/agenda/registro/contatos/prospect", { data });

  const formData = new FormData();
  for (var f of files) {
    formData.append(`upload`, f);
    // const response: any = await apiFiles.post("/upload", formData, {
    //   params: {
    //     codigo: `${qtdPrefixo.slice(String(codigo).length)}${codigo}`,
    //     tipo: tipo,
    //   },
    // });

    // return response;
  }

  formData.append("data", JSON.stringify(data));

  //return true;
  const response = await api.post(
    "/agenda/registro/contatos/prospect",
    formData,
    {
      headers: {
        ...formData,
      },
    }
  );

  return response;
}

async function fetchAgendaRegistrosDeContatosPorCodigoProspect({
  codigo,
  loja,
}: {
  codigo?: string;
  loja?: string;
}) {
  const data = {
    codigo: String(codigo), //: "018274",
    loja: "",
  };

  const response: any = await api.get("/agenda/contatos/prospect", {
    params: data,
  });

  if (response?.data) {
    return response.data;
  }

  return [];
}

async function sendAgendaRegistroContatoCliente({ data, files }: any) {
  //const files: any = file;
  //let formData; //= new FormData();
  //const qtdPrefixo = "00000000";
  //const { codigo, tipoContato } = data;
  /*if (files.length > 0) {
    formData = await sendAgendaRegistroDeContatoAnexos({
      files,
      codigo,
      tipo: tipoContato,
    });
  }*/

  //formData.append("codigo", data.codigo); //JSON.stringify(data));
  //formData.append("tipo", "1"); //JSON.stringify(data));

  const formData = new FormData();
  for (var f of files) {
    formData.append(`upload`, f);
    // const response: any = await apiFiles.post("/upload", formData, {
    //   params: {
    //     codigo: `${qtdPrefixo.slice(String(codigo).length)}${codigo}`,
    //     tipo: tipo,
    //   },
    // });

    // return response;
  }

  formData.append("data", JSON.stringify(data));

  //return true;
  const response = await api.post(
    "/agenda/registro/contatos/cliente",
    formData,
    {
      headers: {
        ...formData,
      },
    }
  );

  return response;
}

async function fetchAgendaRegistrosDeContatosPorCodigoCliente({
  codigo,
  loja,
}: {
  codigo?: string;
  loja?: string;
}) {
  const data = {
    codigo: String(codigo),
    loja,
  };

  const response: any = await api.get("/agenda/contatos/cliente", {
    params: data,
  });

  if (response?.data) {
    return response.data;
  }

  return [];
}

async function fetchAgendaRegistrosDeContatosAnexosPorCodigo({
  codigo,
  tipo,
}: {
  codigo: string;
  tipo: string;
}) {
  //const qtdPrefixo = "00000000";

  /*const response: any = await apiFiles.get("/listar", {
    params: {
      codigo: `${qtdPrefixo.slice(String(codigo).length)}${codigo}`, //: "00074203",
      tipo,
    },
  });*/
  const response: any = await api.get("/agenda/contatos/anexos", {
    params: {
      codigo, //: `${qtdPrefixo.slice(String(codigo).length)}${codigo}`, //: "00074203",
      tipo,
    },
  });

  if (response?.data) {
    return response.data;
  }

  return [];
}

async function fetchAgendaRegistrosDeContatosDownloadAnexosPorCodigo(
  codigo: string,
  tipo: string,
  file: string
) {
  const response: any = await api.get("/agenda/contatos/download", {
    params: {
      codigo,
      tipo,
      file,
    },
  });

  if (response?.data) {
    return response.data;
  }

  return [];
}

//------------------------------------------------------------------------------

// CONTROLE COLETA
async function fetchControleColetaPorCodigoCliente({
  codigo,
  loja,
}: {
  codigo?: string;
  loja?: string;
}) {
  const data = {
    codigo,
    loja,
  };

  const response: any = await api.get("/cliente/controle/coleta", {
    params: data,
  });

  if (response?.data) {
    return response;
  }

  return [];
}
//------------------------------------------------------------------------------

export {
  login,
  logout,
  askPasswordChange,
  confirmPasswordChange,
  changePassword,
  fetchLoggedUser,
  fetchClientesRepresentante,
  updateClienteAtivo,
  fetchProdutos,
  fetchProdutosAll,
  fetchCondicoesPagamento,
  fetchMPP,
  fetchPromocoes,
  updateItems,
  uploadPlanilha,
  fetchHistoricoCompraProduto,
  fetchFaturas,
  fetchPedidos,
  fetchTransportadoras,
  fetchProdutosCampanha,
  fetchProdutosParcialCampanha,
  createMotivoSaida,
  createPedido,
  createOrcamento, //JL-HF_06-2022
  fetchClienteOrcamento, //JL-HF_06-2022
  fetchInteracoesOrcamento, //JL-HF_06-2022
  solicitaAprovacaoOrcamento, //JL-HF_06-2022
  fetchSupervisorOrcamentos, //JL-HF_06-2022
  supervisorOrcamentosCarregarProdutos, //JL-HF_06-2022
  supervisorAprovarOrcamento, //JL-HF_06-2022
  supervisorRejeitarOrcamento, //JL-HF_06-2022
  gravarOrcamentoInteracao, //JL-HF_06-2022
  solicitaOrcamentoPdf, //JL-HF_10-2022
  solicitaPedidoPdf, //JL-HF_10-2022
  solicitaNotaFiscaPdf, //JL-HF_06-2022
  fetchGruposProdutosPorCNPJ, //JL-HF_11-2022
  downloadPDFOrcamento, //JL-HF_02-2023
  fetchSaldoMetaCliente,
  fetchProspect, //JL-HF_07-2023
  fetchProspectCodigo, //JL-HF_07-2023
  sendEfetivarProspect, //JL-HF_07-2023
  sendUpdateProspect, //JL-HF_07-2023
  sendDeleteProspect, //JL-HF_07-2023
  sendCreateProspect, //JL-HF_07-2023
  solicitaRelatorioPedido, //JL-HF_09-2023
  solicitaRelatorioFaturamento, //JL-HF_09-2023
  fetchGrupoVendasAll, //JL-HF_09-2023
  fetchAgendaConcorrentes, //JL-HF_09-2023
  fetchAgendaCodigoInatividade, //JL-HF_09-2023
  sendAgendaRegistroContatoProsppect, //JL-HF_07-2023
  sendAgendaRegistroContatoCliente, //JL-HF_07-2023
  fetchControleColetaPorCodigoCliente, //JL-HF_09-2023
  fetchAgendaRegistrosDeContatosPorCodigoCliente, //JL-HF_07-2023
  fetchAgendaRegistrosDeContatosPorCodigoProspect, //JL-HF_07-2023
  updateProdutoSugeridosAtualizaMPPCodigoCliente, //JL-HF_07-2023
  fetchDisponibilidadeProduto, //JL-HF_07-2023
  fetchProdutosPesquisa, //JL-HF_11-2023
  fetchAgendaRegistrosDeContatosAnexosPorCodigo, //JL-HF_11-2023
  fetchAgendaRegistrosDeContatosDownloadAnexosPorCodigo, //JL-HF_11-2023
};

export default api;
