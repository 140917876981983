import { AxiosResponse } from "axios";
import React, { FormEvent, useEffect, useMemo, useState } from "react";
import { FiThumbsUp, FiX, FiEye, FiEyeOff } from "react-icons/fi";
import Select from "react-select";

import { createPedido } from "../../services/api";
import { ItemPedido, Transportadora, User } from "../../typings";
import Input from "../Input";
import PedidoMessageFrete from "../PedidoMessageFrete";

import cnpjCpfMackCustom from "../../utils/formatarCnpjCpf"; ///JL-HF_2023-09
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

interface PedidoResumoProps {
  transportadoras: Transportadora[];
  quantidadeItens: number;
  condicaoPagamento: string;
  valorTotalSemImpostos: number;
  valorTotalComImpostos: number;
  valorTotalDescontos: number;
  ipiTotal: number;
  stTotal: number;
  frete: { tipo: "CIF" | "FOB"; valor: number };
  pedidoTipo: "REPOSICAO" | "BONIFICACAO";
  itens: ItemPedido[];
  user: User | null;
  onConfirm(response: AxiosResponse): void;
  onCancel(): void;
}

const PedidoResumo: React.FC<PedidoResumoProps> = ({
  transportadoras = [],
  quantidadeItens,
  condicaoPagamento,
  valorTotalSemImpostos,
  valorTotalComImpostos,
  valorTotalDescontos,
  ipiTotal,
  stTotal,
  frete = { tipo: "CIF", valor: 0 },
  itens = [],
  pedidoTipo,
  user,
  onConfirm = () => {},
  onCancel = () => {},
}) => {
  const [transportadora, setTransportadora] = useState<Transportadora>({
    codigo: "",
    nome: "",
    cgc: "",
    endereco: "",
    telefone: "",
    email: "", //JL_2022-06
  });
  const [novaTransportadora, setNovaTransportadora] = useState<Transportadora>({
    codigo: "",
    nome: "",
    cgc: "",
    endereco: "",
    telefone: "",
    email: "", //JL_2022-06
  });

  const [exibirDetalhesProduto, setExibirDetalhesProduto] = useState(false);

  const [pedidoCliente, setPedidoCliente] = useState("");
  const [pedidoRepresentante, setPedidoRepresentante] = useState("");
  const [dataEntregaProgramada, setDataEntregaProgramada] = useState("");
  const [aceitaEntregaParcial, setAceitaEntregaParcial] = useState(true);
  const [showFormTransportadora, setShowFormTransportadora] = useState(false);
  const [justificativaDesconto, setJustificativaDesconto] = useState("");
  const [freteFOB, setFreteFOB] = useState(frete.tipo === "FOB");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [justificativaDescontoError, setJustificativaDescontoError] = useState(
    ""
  );
  const [transportadoraErrors, setTransportadoraErrors] = useState({
    nome: "",
    cgc: "",
    endereco: "",
    telefone: "",
    email: "",
  });
  const [dataEntregaProgramadaError, setDataEntregaProgramadaError] = useState(
    ""
  );

  //JL-HF_2024-08
  const [observacoesPedidoFOB, setObservacoesPedidoFOB] = useState("");

  const tomorrow = useMemo(() => {
    const d = new Date();
    const day = d.getDate() + 1;
    const month = d.getMonth() + 1;
    const year = d.getFullYear();
    const formatedDay = day < 10 ? `0${day}` : day;
    const formatedMonth = month < 10 ? `0${month}` : month;

    return `${year}-${formatedMonth}-${formatedDay}`;
  }, []);

  function validateJustificativaDesconto() {
    let isValid = true;

    setJustificativaDescontoError("");

    if (justificativaDesconto === "") {
      isValid = false;

      setJustificativaDescontoError("Preenchimento obrigatório");
    } else {
      if (justificativaDesconto.length < 3) {
        isValid = false;

        setJustificativaDescontoError("Digite pelo menos 3 caracteres");
      }
    }

    return isValid;
  }

  function validateTransportadora() {
    let isValid = true;

    setTransportadoraErrors(() => ({
      nome: "",
      cgc: "",
      endereco: "",
      telefone: "",
      email: "", //email: JL_2022-06
    }));

    if (showFormTransportadora) {
      if (novaTransportadora.nome === "") {
        isValid = false;

        setTransportadoraErrors((values) => ({
          ...values,
          nome: "Preenchimento obrigatório",
        }));
      }

      if (novaTransportadora.cgc === "") {
        isValid = false;

        setTransportadoraErrors((values) => ({
          ...values,
          cgc: "Preenchimento obrigatório",
        }));
      }

      if (novaTransportadora.endereco === "") {
        isValid = false;

        setTransportadoraErrors((values) => ({
          ...values,
          endereco: "Preenchimento obrigatório",
        }));
      }

      if (novaTransportadora.telefone === "") {
        isValid = false;

        setTransportadoraErrors((values) => ({
          ...values,
          telefone: "Preenchimento obrigatório",
        }));
      }
    } else {
      if (transportadora.nome === "") {
        isValid = false;

        setTransportadoraErrors((values) => ({
          ...values,
          nome: "Preenchimento obrigatório",
        }));
      }
    }

    return isValid;
  }

  function validateDataEntregaProgramada() {
    let isValid = true;

    setDataEntregaProgramadaError("");

    if (dataEntregaProgramada !== "" && dataEntregaProgramada < tomorrow) {
      isValid = false;

      setDataEntregaProgramadaError("Data deve ser posterior ao dia de hoje");
    }

    return isValid;
  }

  function scrollToTop() {
    const swalContainer = document.querySelector(".swal2-container");

    if (swalContainer) {
      swalContainer.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setErrorMessage("");

      if (
        valorTotalDescontos > 0 &&
        validateJustificativaDesconto() === false
      ) {
        scrollToTop();

        return false;
      }

      if (freteFOB && validateTransportadora() === false) {
        scrollToTop();

        return false;
      }

      if (validateDataEntregaProgramada() === false) {
        scrollToTop();

        return false;
      }

      setLoading(true);

      const response = await createPedido({
        condicaoPagamento: condicaoPagamento.split("-")[0].trim(),
        tipoFrete: freteFOB ? "F" : "C",
        tipoPedido: pedidoTipo, //JL-HF_2021-04
        valorFrete: frete.valor,
        transportadora: freteFOB
          ? showFormTransportadora
            ? novaTransportadora
            : transportadora
          : null,
        pedidoCliente,
        pedidoRepresentante,
        aceitaParcial: aceitaEntregaParcial,
        dataProg: dataEntregaProgramada,
        justificativaDesconto: justificativaDesconto,
        observacoesPedidoFOB: freteFOB ? observacoesPedidoFOB : "", //JL-HF_2024-08
        itens: itens.map((item) => ({
          codigo: item.produto.codigo,
          quantidade: item.quantidadeComprar,
          percentualDesconto: item.percentualDesconto,
          entrega2Data: item.entrega2Data,
          setLote: item.setLote,
        })),
      });

      setLoading(false);

      if (response.data) {
        onConfirm(response);
      }
    } catch (error) {
      scrollToTop();

      let message =
        "Ocorreu um erro no processamento da requisição. Por favor, tente novamente mais tarde.";

      setLoading(false);

      /*if (error?.response?.data?.message) {
        message = error.response?.data?.message;
      }*/

      setErrorMessage(message);

      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    }
  }

  useEffect(() => {
    setNovaTransportadora({
      codigo: "",
      nome: "",
      cgc: "",
      endereco: "",
      telefone: "",
      email: "",
    }); //email: JL_2022-06
    setTransportadora({
      codigo: "",
      nome: "",
      cgc: "",
      endereco: "",
      telefone: "",
      email: "",
    }); //email: JL_2022-06
    setTransportadoraErrors({
      nome: "",
      cgc: "",
      endereco: "",
      telefone: "",
      email: "", // JL_2022-06
    });
  }, [showFormTransportadora]);

  useEffect(() => {
    setTransportadora({
      codigo: "",
      nome: "",
      cgc: "",
      endereco: "",
      telefone: "",
      email: "",
    }); //email: JL_2022-06
    setShowFormTransportadora(false);
  }, [freteFOB]);

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      onReset={() => onCancel()}
      style={{ position: "relative" }}
    >
      <fieldset disabled={loading} className="row">
        {loading && (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(255, 255, 255, 0.75)",
              zIndex: 1000,
            }}
          >
            <div className="spinner-border text-info"></div>
            <div className="text-primary mt-4">Enviando pedido.</div>
          </div>
        )}

        {errorMessage && (
          <div className="alert alert-danger text-left">{errorMessage}</div>
        )}
        <label className="col-md-12 col-sx-12 text-left pl-0">
          <strong>Número da ordem de compra</strong>
        </label>
        <div className="d-flex align-items-left justify-content-between col-md-12 pl-0 pr-0">
          {(user?.tipo.nome === "REPRESENTANTE" ||
            user?.tipo.nome === "AGENTE") && (
            <div className="col col-md-6 form pl-0">
              <Input
                className="form-control"
                label="Representante"
                name="pedidorepresentante"
                value={pedidoRepresentante}
                onChange={(e) => setPedidoRepresentante(e.target.value)}
                maxLength={300}
              />
            </div>
          )}
          {/* <div className="row col col-md-2" /> */}
          <div className="col col-md-6 form pr-0">
            <Input
              label="Cliente"
              name="pedidocliente"
              value={pedidoCliente}
              className="form-control"
              onChange={(e) => setPedidoCliente(e.target.value)}
              maxLength={300}
            />
          </div>
        </div>
        {valorTotalDescontos > 0 && (
          <Input
            className="col-md-12"
            label="Justificativa do desconto aplicado"
            name="justificativaDesconto"
            value={justificativaDesconto}
            onChange={(e) => setJustificativaDesconto(e.target.value)}
            maxLength={300}
            errorMessage={justificativaDescontoError}
            onBlur={() => validateJustificativaDesconto()}
          />
        )}

        <div className="d-flex align-items-center justify-content-between">
          {frete.tipo === "CIF" && (
            <div className="col-md-6 row">
              <label>Qual tipo de frete?</label>
              <br />
              <div className="form-group d-flex align-items-left justify-content-between">
                <div className="col-md-12">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="freteFOBFalse"
                      value="false"
                      checked={freteFOB === false}
                      onChange={(e) => setFreteFOB(false)}
                    />
                    <label className="form-check-label" htmlFor="freteFOBFalse">
                      CIF
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="freteFOBTrue"
                      value="true"
                      checked={freteFOB === true}
                      onChange={(e) => setFreteFOB(true)}
                    />
                    <label className="form-check-label" htmlFor="freteFOBTrue">
                      FOB
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="col-md-6 row">
            <label>Aceita entregas parciais?</label>
            <br />
            <div className="form-group d-flex align-items-center justify-content-between">
              <div className="col-md-12">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="entregaParcialTrue"
                    value="true"
                    checked={aceitaEntregaParcial === true}
                    onChange={(e) => setAceitaEntregaParcial(true)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="entregaParcialTrue"
                  >
                    SIM
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    id="entregaParcialFalse"
                    value="false"
                    checked={aceitaEntregaParcial === false}
                    onChange={(e) => setAceitaEntregaParcial(false)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="entregaParcialFalse"
                  >
                    NÃO
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {freteFOB && (
          <div className="col col-md-12 p-0">
            {/*JL-HF_08-2024 | Add campo para observação pedido quando FOB | solicitante Douglas Chagas */}
            <div className="form-group text-left collum">
              <Input
                className="col-md-12"
                label="Observação de frete"
                name="observacoesNota"
                value={observacoesPedidoFOB}
                placeholder="Digite observações com texto máximo de 50 letras"
                onChange={(e) => setObservacoesPedidoFOB(e.target.value)}
                maxLength={50}
                errorMessage={
                  observacoesPedidoFOB.length >= 50
                    ? "Texto deve ter no máximo 50 letras!"
                    : ""
                }
              />
            </div>
            {/*END CAMPO OBS.*/}
            <div className="form-group text-left collum">
              <label htmlFor="transportadora">Transportadora</label>
              <div className="d-flex mb-3">
                <div className="flex-grow-1 mr-1">
                  <Select
                    id="transportadora"
                    options={transportadoras}
                    getOptionLabel={(item) =>
                      `${cnpjCpfMackCustom(item.cgc)} - ${item.nome}`
                    }
                    getOptionValue={(item) => item.codigo}
                    placeholder="Selecione a transportadora"
                    styles={{
                      option: (provided) => ({
                        ...provided,
                        fontSize: ".9rem !important",
                        textAlign: "left",
                        minWidth: "400px",
                      }),
                      control: (provided) => ({
                        ...provided,
                        borderRadius: 0,
                        borderColor:
                          showFormTransportadora === false &&
                          transportadoraErrors.nome
                            ? "#dc3545"
                            : "#204d74",
                      }),
                    }}
                    value={transportadora.codigo ? transportadora : null}
                    onChange={(value) =>
                      setTransportadora(value as Transportadora)
                    }
                    isDisabled={showFormTransportadora}
                    onBlur={() => validateTransportadora()}
                  />
                </div>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={() =>
                    setShowFormTransportadora(!showFormTransportadora)
                  }
                >
                  {showFormTransportadora ? "Cancelar" : "Inserir nova"}
                </button>
              </div>
              {showFormTransportadora === false && transportadoraErrors.nome && (
                <div
                  className="invalid-feedback d-block"
                  style={{ marginTop: "-15px" }}
                >
                  {transportadoraErrors.nome}
                </div>
              )}
            </div>

            {showFormTransportadora && (
              <div className="card card-secondary mb-3">
                <div className="card-header">
                  <strong>Nova transportadora</strong>
                </div>
                <div className="card-body">
                  <Input
                    label="Nome"
                    name="nomeTransportadora"
                    value={novaTransportadora.nome}
                    onChange={(e) =>
                      setNovaTransportadora({
                        ...novaTransportadora,
                        nome: e.target.value,
                      })
                    }
                    placeholder={"Nome da transportadora"}
                    maxLength={300}
                    errorMessage={transportadoraErrors.nome}
                    onBlur={() => validateTransportadora()}
                  />

                  <Input
                    label="CNPJ"
                    name="cnpjTransportadora"
                    mask="99.999.999/9999-99"
                    value={novaTransportadora.cgc}
                    onChange={(e) =>
                      setNovaTransportadora({
                        ...novaTransportadora,
                        cgc: e.target.value,
                      })
                    }
                    placeholder={"00.000.000/0000-00"}
                    errorMessage={transportadoraErrors.cgc}
                    onBlur={() => validateTransportadora()}
                  />

                  <Input
                    label="Endereço"
                    name="enderecoTransportadora"
                    value={novaTransportadora.endereco}
                    onChange={(e) =>
                      setNovaTransportadora({
                        ...novaTransportadora,
                        endereco: e.target.value,
                      })
                    }
                    placeholder={"Endereço da transportadora"}
                    maxLength={300}
                    errorMessage={transportadoraErrors.endereco}
                    onBlur={() => validateTransportadora()}
                  />

                  <Input
                    label="Telefone"
                    name="telefoneTransportadora"
                    value={novaTransportadora.telefone}
                    placeholder={"00 00000-0000"}
                    onChange={(e) =>
                      setNovaTransportadora({
                        ...novaTransportadora,
                        telefone: e.target.value,
                      })
                    }
                    mask="99 9999-9999"
                    maxLength={30}
                    errorMessage={transportadoraErrors.telefone}
                    onBlur={() => validateTransportadora()}
                  />
                  {/* DEV-JL 2022-06*/}
                  <Input
                    label="E-mail"
                    name="emailTransportadora"
                    value={novaTransportadora.email}
                    onChange={(e) =>
                      setNovaTransportadora({
                        ...novaTransportadora,
                        email: e.target.value,
                      })
                    }
                    placeholder={"emailtransportadora@dominio.com.br"}
                    maxLength={30}
                    errorMessage={transportadoraErrors.email}
                    onBlur={() => validateTransportadora()}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <Input
          className="col-md-12"
          label="Data para entrega programada"
          name="dataEntregaProgramada"
          value={dataEntregaProgramada}
          type="date"
          min={tomorrow}
          errorMessage={dataEntregaProgramadaError}
          onBlur={() => validateDataEntregaProgramada()}
          onChange={(e) => setDataEntregaProgramada(e.target.value)}
        />

        <table className="table table-striped table-bordered">
          <tbody>
            {exibirDetalhesProduto && (
              <tr className="p-0 m-0">
                <td colSpan={2} className="p-0 m-0">
                  <table className="table table-striped table-bordered p-0 m-0">
                    <thead>
                      <tr>
                        <th colSpan={4} className="text-center">
                          <small className="text-center">
                            <strong>Itens do pedido</strong>
                          </small>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <small>Grupo</small>
                        </th>
                        <th>
                          <small>Código</small>
                        </th>
                        <th>
                          <small>Descrição</small>
                        </th>
                        <th>
                          <small title="Quantidade comprar do produto">
                            Qtd.
                          </small>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {itens
                        .filter((i) => i.quantidadeComprar > 0)
                        .map((item: any) => (
                          <tr key={item.codigo}>
                            <td>
                              <small>{item.codigogrupo}</small>
                            </td>
                            <td>
                              <small>{item.codigo}</small>
                            </td>
                            <td className="text-left">
                              <small>{item.descricao}</small>
                            </td>
                            <td className="text-right">
                              <small>{item.quantidadeComprar}</small>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            <tr>
              <td
                className="text-left d-block d-md-flex justify-content-between align-items-center  m-1 p-0"
                style={{ border: "none" }}
              >
                <span className="col-md-10 ml-1 pl-1">Quantidade de itens</span>
                <button
                  type="button"
                  style={{
                    outline: "none",
                    border: "none",
                    background: "transparent",
                  }}
                  className="col-md-2 toggle btn-sm mr-1 mt-1"
                  onClick={() =>
                    setExibirDetalhesProduto(!exibirDetalhesProduto)
                  }
                >
                  {exibirDetalhesProduto ? (
                    <FaAngleDown
                      size={18}
                      color="#dc3545"
                      title="Oculta detalhes dos itens"
                    />
                  ) : (
                    <FaAngleUp
                      size={18}
                      color="#28a745"
                      title="Exibir detalhes itens"
                    />
                  )}
                </button>
              </td>
              <td className="text-right">{quantidadeItens}</td>
            </tr>
            <tr>
              <td className="text-left">Condição de pagamento</td>
              <td className="text-right">{condicaoPagamento}</td>
            </tr>
            {valorTotalDescontos > 0 && (
              <tr>
                <td className="text-left">Total de descontos</td>
                <td className="text-right">
                  {valorTotalDescontos.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
            )}
            <tr>
              <td className="text-left">Total sem impostos</td>
              <td className="text-right">
                {valorTotalSemImpostos.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            <tr>
              <td className="text-left">Total de IPI</td>
              <td className="text-right">
                {ipiTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            <tr>
              <td className="text-left">Total de ST</td>
              <td className="text-right">
                {stTotal.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            <tr>
              <td className="text-left">
                <strong>Total a pagar (com impostos)</strong>
              </td>
              <td className="text-right">
                {valorTotalComImpostos.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </td>
            </tr>
            {/* JL-HF_19-10-2021  Remover calculo frete (Solicitado por Douglas Chagas)*/}
            {/* {pedidoTipo !== 'BONIFICACAO' && (
              <tr>
                <td className="text-left">
                  <strong>Frete</strong>
                </td>
                <td className="text-right">
                  <span>{freteFOB ? 'FOB' : 'CIF'}</span>
                  {freteFOB === false && frete.valor > 0 && (
                    <span> / {frete.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                  )}
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
        <div className="col-md-12 collum p-0 m-0">
          {/* JL-HF_19-10-2021  Remover calculo frete (Solicitado por Douglas Chagas)*/}
          {pedidoTipo !== "BONIFICACAO" && (
            <div className="row d-block flex-fill justify-content-start my-0 m-0 p-0">
              <PedidoMessageFrete />
            </div>
          )}

          <p>Deseja confirmar o envio do pedido?</p>

          <div className="row mt-2">
            <div className="col">
              <button
                type="reset"
                className="btn btn-block btn-lg btn-outline-danger d-flex align-items-center justify-content-center"
              >
                <FiX size={24} className="mr-3" />
                Não
              </button>
            </div>
            <div className="col">
              <button
                type="submit"
                className="btn btn-block btn-lg btn-outline-success d-flex align-items-center justify-content-center"
              >
                <FiThumbsUp size={24} className="mr-3" />
                Sim
              </button>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
};

export default PedidoResumo;
