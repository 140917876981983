import React, {
  useState,
  FormEvent,
  useRef,
  useEffect,
  useCallback,
} from "react";
import {
  FiSearch,
  FiSlash,
  FiPlusSquare,
  FiCheckCircle,
  FiXCircle,
} from "react-icons/fi";

import { fetchProdutos } from "../../services/api";
import { Produto } from "../../typings";
import ENV from "../../ENV";

interface ProdutoSearchProps {
  onSelect(produto: any): void;
  searchText: string;
  grupo: string | "";
}

interface ProdutoSearchState {
  searchText: string;
  grupo: string;
  produtos: Produto[];
  loading: boolean;
  message: string;
}

const ProdutoSearch: React.FC<ProdutoSearchProps> = ({
  onSelect = () => {},
  searchText,
  grupo,
}) => {
  const [listProdutos, setListProdutos] = useState<any[]>([]); //JL-HF_2022-06 ADD Lista de produtos
  const [state, setState] = useState<ProdutoSearchState>({
    searchText: searchText, //'',
    grupo: grupo,
    produtos: [],
    loading: false,
    message: "",
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(
    async (e: FormEvent | null) => {
      if (e) {
        e.preventDefault();
      }

      try {
        setState((v) => ({ ...v, loading: true }));

        //Add filter por codigo proguro - JL-HF_2022-11 (Solicitado por Douglas Chagas)
        const response = await fetchProdutos({
          searchText: state.searchText,
          grupo: state.grupo,
        });

        const items = response.data.items;
        let produtos = response.data.items;

        if (items.length === 0) {
          setState((v) => ({
            ...v,
            message: `O termo <strong>${state.searchText}</strong> não retornou nenhum resultado.`,
          }));

          setTimeout(() => {
            setState((v) => ({ ...v, message: "" }));
          }, 3000);
        }

        if (state.grupo!.length > 0 && items.length > 0) {
          produtos = items.filter(
            (item: any) => item?.grupo?.codigo === state.grupo
          );
        }

        setState((v) => ({ ...v, produtos })); //:  response.data.items }));
      } catch (error) {
        console.log(error);
      } finally {
        setState((v) => ({ ...v, loading: false }));
      }
    },
    [state.grupo, state.searchText]
  );

  function handleReset() {
    setState({
      searchText: "",
      grupo: "",
      produtos: [],
      loading: false,
      message: "",
    });

    inputRef.current?.focus();
  }

  /*function handleSelect(produto: any) {
    onSelect(produto);
  }*/

  /*JL-HF_2022-06 ADD Lista de produtos */
  function handleSelect(produto: any) {
    //onSelect(produto);
    const isProduto = listProdutos.find((p) => p === produto);

    if (isProduto) {
      setListProdutos(listProdutos.filter((f) => f !== produto));
      return;
    }

    setListProdutos([...listProdutos, produto]);
  }

  function handleAddSelect() {
    onSelect(listProdutos);
    setListProdutos([]);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (state.searchText.length >= 3) {
        handleSearch(null);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [state.searchText, handleSearch, searchText.length]);

  return (
    <div>
      <form onSubmit={handleSearch} onReset={() => handleReset()}>
        <div className="col-md-12 pr-4 d-flex flex-row align-items-center justify-content-between ">
          <div className="col-md-8" />
          <button
            type="button"
            disabled={state.produtos.length === 0}
            className="col-md-2 btn btn-sm btn-primary ml-4"
            title="Marcar todos os produtos"
            onClick={() => setListProdutos(state.produtos)}
          >
            <FiCheckCircle size={24} className="pr-1" />
            Marcar
          </button>
          <button
            type="button"
            disabled={state.produtos.length === 0}
            className="col-md-2 btn btn-sm btn-danger ml-0 mr-0 pr-0"
            title="Desmarcar todos os produtos"
            onClick={() => setListProdutos([])}
          >
            <FiXCircle size={24} className="pr-1" />
            Desmarcar
          </button>
        </div>
        <div className="input-group mb-3">
          <input
            type="text"
            ref={inputRef}
            autoFocus
            className="form-control form-control-lg"
            placeholder="Digite o código ou nome do produto"
            value={state.searchText}
            onChange={(e) => setState({ ...state, searchText: e.target.value })}
          />
          <div className="input-group-append">
            <button type="submit" className="btn btn-lg btn-primary">
              <FiSearch />
            </button>
            {listProdutos.length > 0 && (
              <button
                type="button"
                className="btn btn-lg btn-outline-primary"
                title="Adicionar produtos selecionados"
                onClick={handleAddSelect}
              >
                <FiPlusSquare />
              </button>
            )}
            {state.searchText && (
              <button type="reset" className="btn btn-lg btn-outline-primary">
                <FiSlash />
              </button>
            )}
          </div>
        </div>
      </form>

      {state.message && (
        <div
          className="alert alert-warning text-center"
          dangerouslySetInnerHTML={{ __html: state.message }}
        ></div>
      )}

      {state.produtos.map((produto) => (
        <div
          className="d-flex flex-fill align-items-center border-right border-primary p-2"
          key={`${produto.id}-${produto.codigo}`}
        >
          <input
            type="checkbox"
            key={`${produto.id}-${produto.codigo}`}
            id={`${produto.id}-${produto.codigo}`}
            checked={listProdutos.find((i) => i === produto)}
            style={{
              width: "20px",
              height: "20px",
              /*marginTop: `${isVisible ? '50px' : '0'}`, //JL-HF_06-2022*/
            }}
            className="btn btn-light btn-block d-flex flex-row align-items-center justify-content-between mb-3"
            onClick={() => handleSelect(produto)}
          />
          <div className="d-flex align-items-center pl-4">
            <img
              src={`${ENV.ASSETS_URL}/${produto.codigo}.jpg`}
              alt={produto.titulo}
              width="80"
            />
          </div>
          <div className="flex-grow-1 p-2 text-left">
            <h6 className="card-title mb-1 text-primary">{produto.titulo}</h6>

            <div>
              <small className="text-muted">Modelo: {produto.modelo}</small>
            </div>
            <div>
              <small className="text-muted">SKU: {produto.codigo}</small>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProdutoSearch;
